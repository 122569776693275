<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="20"
      height="20"
      fill="none"
      viewBox="0 0 20 20"
    >
      <path
        fill="#B82025"
        d="M16.875 0H3.125C1.402 0 0 1.402 0 3.125v.04c0 1.722 1.402 3.124 3.125 3.124l.04-.002v10.588c0 1.723 1.4 3.125 3.124 3.125h7.461c1.723 0 3.125-1.402 3.125-3.125 0-.431-.35-.781-.781-.781-.432 0-.782.35-.782.781 0 .862-.7 1.563-1.562 1.563H6.29c-.862 0-1.563-.701-1.563-1.563V1.562h10.585V12.97c0 .431.35.781.782.781.431 0 .781-.35.781-.781v-6.68C18.598 6.29 20 4.887 20 3.164v-.039C20 1.402 18.598 0 16.875 0zM3.165 4.729l-.04-.002c-.862 0-1.563-.701-1.563-1.563v-.039c0-.862.701-1.563 1.563-1.563h.04V4.73zm15.273-1.565c0 .862-.701 1.563-1.563 1.563V1.562c.862 0 1.563.701 1.563 1.563v.04z"
      />
      <path
        fill="#B82025"
        d="M10.005 6.445C7.894 6.445 6.177 8.04 6.177 10c0 1.96 1.717 3.555 3.828 3.555 2.11 0 3.828-1.595 3.828-3.555 0-1.96-1.717-3.555-3.828-3.555zm0 5.547c-1.25 0-2.266-.894-2.266-1.992 0-1.099 1.017-1.992 2.266-1.992S12.27 8.9 12.27 10c0 1.098-1.016 1.992-2.265 1.992zM10.04 16.992c.43 0 .78-.35.78-.781 0-.431-.35-.781-.78-.781-.432 0-.782.35-.782.78 0 .432.35.782.781.782zM10.04 4.57c.43 0 .78-.35.78-.78 0-.432-.35-.782-.78-.782-.432 0-.782.35-.782.781 0 .432.35.781.781.781z"
      />
    </svg>
  </div>
</template>

<script>
import { Vue } from "vue-class-component";

export default class Invoice extends Vue {}
</script>
